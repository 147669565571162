import styles from './AffUserItem.module.scss';
import { AffUserItemProps } from './AffUserItem.props';
import cn from 'classnames';

export const AffUserItem = ({ affUser }: AffUserItemProps) => {
  const groupDate: string[] = String(affUser.registration_date).split('T')[0].split('-');
  const formattedGroupDate: string = `${groupDate[2]}-${groupDate[1]}-${groupDate[0]}`;

  return (
    <div className={styles.wrapper}>
      <div className={styles.idWrapper}>{affUser.affilate_id}</div>
      <div className={styles.usernameWrapper}>{affUser.username}</div>
      <div className={styles.registrationWrapper}>{formattedGroupDate}</div>
      <div className={styles.countryWrapper}>{affUser.country} </div>
      <div className={styles.phoneWrapper}>{affUser.phone}</div>
      <div className={styles.depositWrapper}>{affUser.has_deposit}</div>
      <div className={styles.availableWrapper}>{affUser.available_sum}</div>
      <div className={styles.date_paid}>{affUser.date_paid?.split("T")[0]}</div>
      <div
        className={cn(styles.playersWrapper, {
          [styles.green]: affUser.diff > 0,
          [styles.red]: affUser.diff < 0,
        })}
      >
        {affUser.diff > 0 && (
          <div>
            {affUser.players} <img src="/UpArrow.svg" alt="Stonks!" />
            <sup>{affUser.diff}</sup>
          </div>
        )}
        {affUser.diff < 0 && (
          <div>
            {affUser.players} <img src="/DownArrow.svg" alt="Not stonks :(" />
            <sup>{affUser.diff}</sup>
          </div>
        )}
        {affUser.diff == 0 && <div>{affUser.players}</div>}
      </div>
    </div>
  );
};
