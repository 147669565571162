import { Link } from 'react-router-dom';
import styles from './BackPage.module.scss';
import { IBackPageProps } from './BackPage.props';

export const BackPage = ({ path }: IBackPageProps) => {
  return (
    <Link to={path} className={styles.stepBack}>
      <img src="/BackArrow.svg" alt="" width={30} height={30} />
    </Link>
  );
};
