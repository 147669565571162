import axios from 'axios';
import cookie from 'cookie';

export const protectedAxios = axios.create({
  headers: {
    'api-key': process.env.REACT_APP_API_KEY,
  },
});

protectedAxios.interceptors.request.use(
  (config) => {
    const cookies = cookie.parse(document.cookie);
    const token = cookies.token;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);
