// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SortGroup_sortWrapper__0qUkZ {
  display: flex;
  color: var(--light-green);
}
.SortGroup_sortWrapper__0qUkZ .SortGroup_sortSetting__QHcMb {
  display: flex;
  cursor: pointer;
}
.SortGroup_sortWrapper__0qUkZ .SortGroup_sortSetting__QHcMb .SortGroup_up__70L1z {
  position: relative;
  right: -8px;
  top: -5px;
  transform: rotate(-90deg);
}
.SortGroup_sortWrapper__0qUkZ .SortGroup_sortSetting__QHcMb .SortGroup_down__IdP5W {
  position: relative;
  right: -8px;
  top: -5px;
  transform: rotate(90deg);
}
.SortGroup_sortWrapper__0qUkZ .SortGroup_id__0K3yv {
  width: 6%;
}
.SortGroup_sortWrapper__0qUkZ .SortGroup_username__iiIlt {
  width: 20%;
}
.SortGroup_sortWrapper__0qUkZ .SortGroup_registration__RlwWI {
  width: 10%;
}
.SortGroup_sortWrapper__0qUkZ .SortGroup_deposit__pLiGl {
  width: 10%;
}
.SortGroup_sortWrapper__0qUkZ .SortGroup_country__5q2bg {
  width: 10%;
}
.SortGroup_sortWrapper__0qUkZ .SortGroup_players__900Lt {
  margin-left: auto;
}
.SortGroup_sortWrapper__0qUkZ .SortGroup_available_sum__uKvCY {
  width: 10%;
}
.SortGroup_sortWrapper__0qUkZ .SortGroup_phone__NXdxv {
  width: 16%;
}`, "",{"version":3,"sources":["webpack://./src/components/SortGroup/SortGroup.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,yBAAA;AACJ;AACI;EACI,aAAA;EACA,eAAA;AACR;AACQ;EACI,kBAAA;EACA,WAAA;EACA,SAAA;EACA,yBAAA;AACZ;AAEQ;EACI,kBAAA;EACA,WAAA;EACA,SAAA;EACA,wBAAA;AAAZ;AAII;EACI,SAAA;AAFR;AAKI;EACI,UAAA;AAHR;AAMI;EACI,UAAA;AAJR;AAOI;EACI,UAAA;AALR;AAQI;EACI,UAAA;AANR;AASI;EACI,iBAAA;AAPR;AAUI;EACI,UAAA;AARR;AAWI;EACI,UAAA;AATR","sourcesContent":[".sortWrapper {\n    display: flex;\n    color: var(--light-green);\n\n    .sortSetting {\n        display: flex;\n        cursor: pointer;\n\n        .up {\n            position: relative;\n            right: -8px;\n            top: -5px;\n            transform: rotate(-90deg);\n        }\n\n        .down {\n            position: relative;\n            right: -8px;\n            top: -5px;\n            transform: rotate(90deg);\n        }\n    }\n\n    .id {\n        width: 6%;\n    }\n\n    .username {\n        width: 20%;\n    }\n\n    .registration {\n        width: 10%;\n    }\n\n    .deposit {\n        width: 10%;\n    }\n\n    .country {\n        width: 10%;\n    }\n\n    .players {\n        margin-left: auto;\n    }\n\n    .available_sum {\n        width: 10%;\n    }\n\n    .phone {\n        width: 16%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sortWrapper": `SortGroup_sortWrapper__0qUkZ`,
	"sortSetting": `SortGroup_sortSetting__QHcMb`,
	"up": `SortGroup_up__70L1z`,
	"down": `SortGroup_down__IdP5W`,
	"id": `SortGroup_id__0K3yv`,
	"username": `SortGroup_username__iiIlt`,
	"registration": `SortGroup_registration__RlwWI`,
	"deposit": `SortGroup_deposit__pLiGl`,
	"country": `SortGroup_country__5q2bg`,
	"players": `SortGroup_players__900Lt`,
	"available_sum": `SortGroup_available_sum__uKvCY`,
	"phone": `SortGroup_phone__NXdxv`
};
export default ___CSS_LOADER_EXPORT___;
