import { useEffect } from 'react';
import { CreateGroupForm } from '../../components';
import styles from './AllGroups.module.scss';
import { checkUser } from '../../api';

export const CreateGroupPage = () => {
  useEffect(() => {
    (async () => {
      const user = await checkUser();

      if (user.message.startsWith('error')) {
        window.location.href = '/login';
      }
    })();
  }, []);

  return (
    <div className={styles.wrapper}>
      <CreateGroupForm />
    </div>
  );
};
