// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Footer_wrapper__fOR4R {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  padding: 30px 60px;
  background-color: var(--second-bg);
  border-top: 1px solid var(--border-color);
}`, "",{"version":3,"sources":["webpack://./src/Layout/Footer/Footer.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,YAAA;EACA,kBAAA;EACA,kCAAA;EACA,yCAAA;AACJ","sourcesContent":[".wrapper {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    width: 100vw;\n    padding: 30px 60px;\n    background-color: var(--second-bg);\n    border-top: 1px solid var(--border-color);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Footer_wrapper__fOR4R`
};
export default ___CSS_LOADER_EXPORT___;
