import axios from 'axios';
import { ICategory } from '../types';
import { protectedAxios } from '../utils';

export const getCategories = async (): Promise<ICategory[] | { message: string }> => {
  try {
    const { data } = await protectedAxios.get<ICategory[]>(
      `${process.env.REACT_APP_API_URL}/category`,
    );

    return data;
  } catch (err) {
    return {
      message: 'get categories error',
    };
  }
};

export const deleteCategory = async (
  categoryId: number,
): Promise<ICategory | { message: string }> => {
  try {
    const { data } = await protectedAxios.delete(
      `${process.env.REACT_APP_API_URL}/category/${categoryId}`,
    );

    return data;
  } catch (err) {
    return {
      message: 'delete group error',
    };
  }
};

export const createCategory = async (file: File): Promise<ICategory | { message: string }> => {
  try {
    const formData = new FormData();
    formData.append('file', file);

    const { data } = await protectedAxios.post(`${process.env.REACT_APP_API_URL}/category`, formData);

    return data;
  } catch (err) {
    console.log(err);
    return {
      message: 'create category err',
    };
  }
}