import axios from 'axios';
import { IUserWithToken } from '../types';
import { protectedAxios, setCookie } from '../utils';

export const loginUser = async (
  username: string,
  password: string,
): Promise<IUserWithToken | { message: string }> => {
  try {
    const { data } = await protectedAxios.post<IUserWithToken | { message: string }>(
      `${process.env.REACT_APP_API_URL}/user/login`,
      {
        username,
        password,
      },
    );

    if ('token' in data) {
      setCookie('token', data.token, 7);
    }

    return data;
  } catch {
    return {
      message: 'denied',
    };
  }
};

export const checkUser = async (): Promise<{ message: string }> => {
  try {
    const { data } = await protectedAxios.post(`${process.env.REACT_APP_API_URL}/auth/check`);
    return data;
  } catch (err) {
    console.log(err);
    return {
      message: 'error',
    };
  }
};
