// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Pagination_wrapper__Rvhto {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 20px;
}
.Pagination_wrapper__Rvhto .Pagination_paginationWrapper__BJphU {
  display: flex;
  padding: 0 20px;
}
.Pagination_wrapper__Rvhto .Pagination_paginationWrapper__BJphU .Pagination_item__\\+B4I\\+ {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: var(--main-bg);
  color: var(--light-green);
  border-radius: var(--default-radius);
  cursor: pointer;
  transition: 0.4s;
}
.Pagination_wrapper__Rvhto .Pagination_paginationWrapper__BJphU .Pagination_item__\\+B4I\\+:not(:last-child) {
  margin-right: 20px;
}
.Pagination_wrapper__Rvhto .Pagination_paginationWrapper__BJphU .Pagination_item__\\+B4I\\+:hover {
  background-color: var(--black);
}
.Pagination_wrapper__Rvhto .Pagination_paginationWrapper__BJphU .Pagination_active__q3h4d {
  background-color: var(--black);
}
.Pagination_wrapper__Rvhto .Pagination_btn__RGopE {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.Pagination_wrapper__Rvhto .Pagination_double__V\\+jjG {
  cursor: pointer;
  background-color: transparent;
  margin-top: 4px;
}
.Pagination_wrapper__Rvhto .Pagination_double__V\\+jjG img {
  background-color: var(--second-bg);
}
.Pagination_wrapper__Rvhto .Pagination_left__ye-aW {
  margin-right: 4px;
}
.Pagination_wrapper__Rvhto .Pagination_right__8UvFz {
  margin-left: 4px;
}`, "",{"version":3,"sources":["webpack://./src/components/Pagination/Pagination.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;EACA,gBAAA;AACJ;AACI;EACI,aAAA;EACA,eAAA;AACR;AACQ;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;EACA,gCAAA;EACA,yBAAA;EACA,oCAAA;EACA,eAAA;EACA,gBAAA;AACZ;AACY;EACI,kBAAA;AAChB;AAEY;EACI,8BAAA;AAAhB;AAIQ;EACI,8BAAA;AAFZ;AAMI;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,eAAA;AAJR;AAOI;EACI,eAAA;EACA,6BAAA;EACA,eAAA;AALR;AAOQ;EACI,kCAAA;AALZ;AASI;EACI,iBAAA;AAPR;AAUI;EACI,gBAAA;AARR","sourcesContent":[".wrapper {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    width: 100%;\n    margin-top: 20px;\n\n    .paginationWrapper {\n        display: flex;\n        padding: 0 20px;\n\n        .item {\n            display: flex;\n            justify-content: center;\n            align-items: center;\n            width: 40px;\n            height: 40px;\n            background-color: var(--main-bg);\n            color: var(--light-green);\n            border-radius: var(--default-radius);\n            cursor: pointer;\n            transition: 0.4s;\n\n            &:not(:last-child) {\n                margin-right: 20px;\n            }\n\n            &:hover {\n                background-color: var(--black);\n            }\n        }\n\n        .active {\n            background-color: var(--black);\n        }\n    }\n\n    .btn {\n        display: flex;\n        justify-content: center;\n        align-items: center;\n        cursor: pointer;\n    }\n\n    .double {\n        cursor: pointer;\n        background-color: transparent;\n        margin-top: 4px;\n\n        img {\n            background-color: var(--second-bg);\n        }\n    }\n\n    .left {\n        margin-right: 4px;\n    }\n\n    .right {\n        margin-left: 4px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Pagination_wrapper__Rvhto`,
	"paginationWrapper": `Pagination_paginationWrapper__BJphU`,
	"item": `Pagination_item__+B4I+`,
	"active": `Pagination_active__q3h4d`,
	"btn": `Pagination_btn__RGopE`,
	"double": `Pagination_double__V+jjG`,
	"left": `Pagination_left__ye-aW`,
	"right": `Pagination_right__8UvFz`
};
export default ___CSS_LOADER_EXPORT___;
