// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AffUserItem_wrapper__x0Y5k {
  padding: 10px;
  display: flex;
  align-items: center;
  border: 1px solid var(--border-color);
  border-radius: var(--default-radius);
  color: var(--white);
  width: 100%;
  transition: 0.6s;
}
.AffUserItem_wrapper__x0Y5k:not(:first-child) {
  margin-top: 10px;
}
.AffUserItem_wrapper__x0Y5k:hover {
  background-color: var(--text-black);
}
.AffUserItem_wrapper__x0Y5k .AffUserItem_idWrapper__49ZRP {
  width: 5%;
}
.AffUserItem_wrapper__x0Y5k .AffUserItem_usernameWrapper__Va3Ea {
  width: 20%;
  margin-left: 20px;
}
.AffUserItem_wrapper__x0Y5k .AffUserItem_registrationWrapper__joo1C {
  min-width: 10%;
}
.AffUserItem_wrapper__x0Y5k .AffUserItem_countryWrapper__rPkoJ {
  min-width: 10%;
}
.AffUserItem_wrapper__x0Y5k .AffUserItem_phoneWrapper__7iHiV {
  min-width: 16%;
}
.AffUserItem_wrapper__x0Y5k .AffUserItem_depositWrapper__O3Ve\\+ {
  min-width: 10%;
}
.AffUserItem_wrapper__x0Y5k .AffUserItem_availableWrapper__fnfMv {
  width: 10%;
}
.AffUserItem_wrapper__x0Y5k .AffUserItem_playersWrapper__hBl8o {
  margin-left: auto;
}
.AffUserItem_wrapper__x0Y5k .AffUserItem_playersWrapper__hBl8o div {
  display: flex;
  align-items: center;
}
.AffUserItem_wrapper__x0Y5k .AffUserItem_playersWrapper__hBl8o div sup {
  position: relative;
  bottom: 10px;
}
.AffUserItem_wrapper__x0Y5k .AffUserItem_red__eIyXm {
  color: #e90000;
}
.AffUserItem_wrapper__x0Y5k .AffUserItem_green__6Yb8N {
  color: #19ba00;
}`, "",{"version":3,"sources":["webpack://./src/components/AffUserItem/AffUserItem.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,aAAA;EACA,mBAAA;EACA,qCAAA;EACA,oCAAA;EACA,mBAAA;EACA,WAAA;EACA,gBAAA;AACJ;AACI;EACI,gBAAA;AACR;AAEI;EACI,mCAAA;AAAR;AAGI;EACI,SAAA;AADR;AAII;EACI,UAAA;EACA,iBAAA;AAFR;AAKI;EACI,cAAA;AAHR;AAMI;EACI,cAAA;AAJR;AAOI;EACI,cAAA;AALR;AAQI;EACI,cAAA;AANR;AASI;EACI,UAAA;AAPR;AAUI;EACI,iBAAA;AARR;AAUQ;EACI,aAAA;EACA,mBAAA;AARZ;AAUY;EACI,kBAAA;EACA,YAAA;AARhB;AAaI;EACI,cAAA;AAXR;AAcI;EACI,cAAA;AAZR","sourcesContent":[".wrapper {\n    padding: 10px;\n    display: flex;\n    align-items: center;\n    border: 1px solid var(--border-color);\n    border-radius: var(--default-radius);\n    color: var(--white);\n    width: 100%;\n    transition: 0.6s;\n\n    &:not(:first-child) {\n        margin-top: 10px;\n    }\n\n    &:hover {\n        background-color: var(--text-black);\n    }\n    \n    .idWrapper {\n        width: 5%;\n    }\n\n    .usernameWrapper {\n        width: 20%;\n        margin-left: 20px;\n    }\n\n    .registrationWrapper {\n        min-width: 10%;\n    }\n\n    .countryWrapper {\n        min-width: 10%;\n    }\n\n    .phoneWrapper {\n        min-width: 16%;\n    }\n\n    .depositWrapper {\n        min-width: 10%;\n    }\n\n    .availableWrapper {\n        width: 10%;\n    }\n\n    .playersWrapper {\n        margin-left: auto;\n\n        div {\n            display: flex;\n            align-items: center;\n\n            sup {\n                position: relative;\n                bottom: 10px;\n            }\n        }\n    }\n\n    .red {\n        color: #e90000;\n    }\n\n    .green {\n        color: #19ba00;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `AffUserItem_wrapper__x0Y5k`,
	"idWrapper": `AffUserItem_idWrapper__49ZRP`,
	"usernameWrapper": `AffUserItem_usernameWrapper__Va3Ea`,
	"registrationWrapper": `AffUserItem_registrationWrapper__joo1C`,
	"countryWrapper": `AffUserItem_countryWrapper__rPkoJ`,
	"phoneWrapper": `AffUserItem_phoneWrapper__7iHiV`,
	"depositWrapper": `AffUserItem_depositWrapper__O3Ve+`,
	"availableWrapper": `AffUserItem_availableWrapper__fnfMv`,
	"playersWrapper": `AffUserItem_playersWrapper__hBl8o`,
	"red": `AffUserItem_red__eIyXm`,
	"green": `AffUserItem_green__6Yb8N`
};
export default ___CSS_LOADER_EXPORT___;
