import { useState } from 'react';
import styles from './Login.module.scss';
import { loginUser } from '../../api';
import { IUserWithToken } from '../../types';
import { setCookie } from '../../utils';

export const LoginPage = () => {
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState<boolean>(false);

  const setLoginUser = async () => {
    const user = await loginUser(username, password);

    if ('token' in user) {
      window.location.href = '/';
    } else {
      setError(true);
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.formWrapper}>
        <div className={styles.titleWrapper}>Логин</div>
        <div className={styles.inputWrapper}>
          <input
            type="text"
            placeholder="Имя пользователя"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div className={styles.inputWrapper}>
          <input
            type="password"
            placeholder="Пароль"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        {error && <div className={styles.errorWrapper}>invalid credentials</div>}
        <button
          disabled={password.length > 3 && username.length > 3 ? false : true}
          className={styles.btnWrapper}
          onClick={setLoginUser}
        >
          Войти
        </button>
      </div>
    </div>
  );
};
