import { useState } from "react";
import { CategoryListItemProps } from "./CategoryListItem.props";
import styles from './CategoryListItem.module.scss';
import { DeleteForm } from "../DeleteForm/DeleteForm";
import { Link } from "react-router-dom";

export const CategoryListItem = ({ category }: CategoryListItemProps) => {
    const [deleteForm, setDeleteForm] = useState<boolean>(false);
    const handleDeleteForm = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.preventDefault();
      setDeleteForm(true);
    };
    return (
        <>
            {deleteForm && (
                <DeleteForm
                    entityId={category.id}
                    name={category.name}
                    setOpen={setDeleteForm}
                    entity="category"
                />
            )}
            <Link className={styles.item} to={`/categories/${category.id}`} key={category.id}>
                {category.name}
                <div className={styles.deleteItemWrapper} onClick={(e) => handleDeleteForm(e)}>
                    <img src="/Trash.svg" width={24} height={30} />
                </div>
            </Link>
                
        </>
    )
}