// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DeleteForm_wrapper__Ymakk {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
.DeleteForm_wrapper__Ymakk .DeleteForm_bg__8Els\\+ {
  position: absolute;
  background-color: var(--black);
  opacity: 0.5;
  height: 100vh;
  width: 100vw;
}
.DeleteForm_wrapper__Ymakk .DeleteForm_formWrapper__Z1A1T {
  position: relative;
  padding: 20px;
  border-radius: var(--default-radius);
  background-color: var(--white);
  z-index: 1;
  min-width: 60%;
}
.DeleteForm_wrapper__Ymakk .DeleteForm_formWrapper__Z1A1T .DeleteForm_titleWrapper__5Pas4 {
  font-size: 30px;
  text-align: center;
}
.DeleteForm_wrapper__Ymakk .DeleteForm_formWrapper__Z1A1T .DeleteForm_optionWrapper__XResg {
  display: flex;
  justify-content: space-evenly;
  margin-top: 20px;
}
.DeleteForm_wrapper__Ymakk .DeleteForm_formWrapper__Z1A1T .DeleteForm_optionWrapper__XResg .DeleteForm_option__rjC9n {
  font-size: 24px;
  color: var(--white);
  padding: 10px 20px;
  border-radius: var(--default-radius);
  cursor: pointer;
}
.DeleteForm_wrapper__Ymakk .DeleteForm_formWrapper__Z1A1T .DeleteForm_optionWrapper__XResg .DeleteForm_red__TUoOI {
  background-color: red;
}
.DeleteForm_wrapper__Ymakk .DeleteForm_formWrapper__Z1A1T .DeleteForm_optionWrapper__XResg .DeleteForm_green__VM0wW {
  background-color: green;
}`, "",{"version":3,"sources":["webpack://./src/components/DeleteForm/DeleteForm.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,MAAA;EACA,OAAA;EACA,YAAA;EACA,aAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,UAAA;AACJ;AACI;EACI,kBAAA;EACA,8BAAA;EACA,YAAA;EACA,aAAA;EACA,YAAA;AACR;AAEI;EACI,kBAAA;EACA,aAAA;EACA,oCAAA;EACA,8BAAA;EACA,UAAA;EACA,cAAA;AAAR;AAEQ;EACI,eAAA;EACA,kBAAA;AAAZ;AAGQ;EACI,aAAA;EACA,6BAAA;EACA,gBAAA;AADZ;AAGY;EACI,eAAA;EACA,mBAAA;EACA,kBAAA;EACA,oCAAA;EACA,eAAA;AADhB;AAIY;EACI,qBAAA;AAFhB;AAKY;EACI,uBAAA;AAHhB","sourcesContent":[".wrapper {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100vw;\n    height: 100vh;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    z-index: 1;\n\n    .bg {\n        position: absolute;\n        background-color: var(--black);\n        opacity: 0.5;\n        height: 100vh;\n        width: 100vw;\n    }\n\n    .formWrapper {\n        position: relative;\n        padding: 20px;\n        border-radius: var(--default-radius);\n        background-color: var(--white);\n        z-index: 1;\n        min-width: 60%;\n\n        .titleWrapper {\n            font-size: 30px;\n            text-align: center;\n        }\n\n        .optionWrapper {\n            display: flex;\n            justify-content: space-evenly;\n            margin-top: 20px;\n\n            .option {\n                font-size: 24px;\n                color: var(--white);\n                padding: 10px 20px;\n                border-radius: var(--default-radius);\n                cursor: pointer;\n            }\n\n            .red {\n                background-color: red;\n            }\n\n            .green {\n                background-color: green;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `DeleteForm_wrapper__Ymakk`,
	"bg": `DeleteForm_bg__8Els+`,
	"formWrapper": `DeleteForm_formWrapper__Z1A1T`,
	"titleWrapper": `DeleteForm_titleWrapper__5Pas4`,
	"optionWrapper": `DeleteForm_optionWrapper__XResg`,
	"option": `DeleteForm_option__rjC9n`,
	"red": `DeleteForm_red__TUoOI`,
	"green": `DeleteForm_green__VM0wW`
};
export default ___CSS_LOADER_EXPORT___;
