import { useEffect, useState } from 'react';
import { ICategory } from '../../types';
import { checkUser, getCategories } from '../../api';
import styles from './Categories.module.scss';
import { Link } from 'react-router-dom';
import { DeleteForm } from '../../components/DeleteForm/DeleteForm';
import React from 'react';
import { CategoryListItem } from '../../components';

export const CategoriesPage = () => {
  const [categories, setCategories] = useState<ICategory[]>([]);

  useEffect(() => {
    (async () => {
      const user = await checkUser();

      if (user.message.startsWith('error')) {
        window.location.href = '/login';
      }
    })();

    (async () => {
      const categoriesApi = await getCategories();

      if (Array.isArray(categoriesApi)) {
        setCategories(categoriesApi);
      }
    })();
  }, []);

  return (
    <div className={styles.wrapper}>
      {categories.length > 0 ? (
        categories.map((item: ICategory) => (
          <CategoryListItem category={item} key={item.id} />
        ))
      ) : (
        <div className={styles.notFound}>Not found</div>
      )}
    </div>
  );
};
