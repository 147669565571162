import { IGroup } from '../types';
import { IPopulatedAffUser } from '../types/AffUser';
import { protectedAxios } from '../utils';

export const createGroup = async (
  categoryId: number
): Promise<IGroup | { message: string }> => {
  try {
    const { data } = 
      await protectedAxios.post(`
        ${process.env.REACT_APP_API_URL}/group/category/${categoryId}
      `);

    return data;
  } catch (err) {
    console.log(err);
    return {
      message: 'create group err',
    };
  }
};

export const getGroupsByCategoryId = async (
  id: number,
): Promise<IGroup[] | { message: string }> => {
  try {
    const { data } = await protectedAxios.get<IGroup[]>(
      `${process.env.REACT_APP_API_URL}/group/category/${id}`,
    );

    return data;
  } catch (err) {
    console.log(err);
    return {
      message: 'get all groups error',
    };
  }
};

export const getGroupByDate = async (
  groupDate: string,
  categoryId: number,
  page: number,
  sort: string,
): Promise<IPopulatedAffUser[] | { message: string }> => {
  try {
    const { data } = await protectedAxios.get(
      `${process.env.REACT_APP_API_URL}/group/${groupDate}?categoryId=${categoryId}&page=${page}&sort=${sort}`,
    );

    if ('statusCode' in data) {
      return {
        message: 'not found',
      };
    }
    return data;
  } catch (err) {
    return {
      message: 'get group id error',
    };
  }
};

export const deleteGroup = async (groupId: number): Promise<IGroup | { message: string }> => {
  try {
    const { data } = await protectedAxios.delete(
      `${process.env.REACT_APP_API_URL}/group/${groupId}`,
    );

    return data;
  } catch (err) {
    return {
      message: 'delete group error',
    };
  }
};

export const getGroupIds = async (slug: string, categoryId: number): Promise<{ids: string} | {message: string}> => {
  try {
    const { data } = await protectedAxios.post(`${process.env.REACT_APP_API_URL}/group/${slug}?categoryId=${categoryId}`);

    return data;
  } catch (err) {
    return {
      message: 'get group ids error',
    };
  }
}

export const refreshGroup = async (
  groupSlug: string,
  categoryId: number
): Promise<{ message: string } | { success: boolean }> => {
  try {
    const { data } = await protectedAxios.post(`${process.env.REACT_APP_API_URL}/group/refresh/${groupSlug}?categoryId=${categoryId}`);

    return data;
  } catch (err) {
    return {
      message: 'get group ids error',
    };
  }
}