import { useEffect, useState } from 'react';
import styles from './AllGroups.module.scss';
import { IGroup } from '../../types';
import { BackPage, GroupListItem } from '../../components';
import { checkUser, getGroupsByCategoryId } from '../../api';

export const AllGroupsPage = () => {
  const [groups, setGroups] = useState<IGroup[]>([]);
  const urlParts: string[] = window.location.href.split('/');
  const categoryId: number = +urlParts[urlParts.length - 1];

  useEffect(() => {
    (async () => {
      const user = await checkUser();

      if (user.message.startsWith('error')) {
        window.location.href = '/login';
      }
    })();

    (async () => {
      const apiGroups: IGroup[] | { message: string } = await getGroupsByCategoryId(categoryId);
      if (Array.isArray(apiGroups)) {
        setGroups(apiGroups);
      }
    })();
  }, []);

  return (
    <>
      <BackPage path={'/categories'} />
      <div className={styles.wrapper}>
        {groups?.map((item: IGroup) => {
          return <GroupListItem categoryId={categoryId} key={item.id} group={item} />;
        })}
      </div>
    </>
  );
};
