// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AllGroups_wrapper__s0jBv {
  background-color: var(--second-bg);
  padding: 40px;
  border-radius: var(--default-radius);
  margin-top: 10px;
  border: 1px solid var(--border-color);
}`, "",{"version":3,"sources":["webpack://./src/pages/AllGroups/AllGroups.module.scss"],"names":[],"mappings":"AAAA;EACI,kCAAA;EACA,aAAA;EACA,oCAAA;EACA,gBAAA;EACA,qCAAA;AACJ","sourcesContent":[".wrapper {\n    background-color: var(--second-bg);\n    padding: 40px;\n    border-radius: var(--default-radius);\n    margin-top: 10px;\n    border: 1px solid var(--border-color);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `AllGroups_wrapper__s0jBv`
};
export default ___CSS_LOADER_EXPORT___;
