// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --black: #000;
  --text-black: #333;
  --white: #fff;
  --light-green: #98fb98;
  --main-bg: #312f3d;
  --second-bg: #39374c;
  --border-color: #4a485e;
  --default-radius: 8px;
}

* {
  box-sizing: border-box;
}

body {
  font-family: sans-serif;
  margin: 0;
  padding: 0;
  background-color: var(--main-bg);
  overflow-x: hidden;
}

a {
  text-decoration: none;
}

ul {
  padding: 0;
}

li {
  list-style-type: none;
}

.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.App main {
  flex: 1 1;
  padding: 20px;
}`, "",{"version":3,"sources":["webpack://./src/styles/globals.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,kBAAA;EACA,oBAAA;EACA,uBAAA;EACA,qBAAA;AACJ;;AAEA;EACI,sBAAA;AACJ;;AAEA;EACI,uBAAA;EACA,SAAA;EACA,UAAA;EACA,gCAAA;EACA,kBAAA;AACJ;;AAEA;EACI,qBAAA;AACJ;;AAEA;EACI,UAAA;AACJ;;AAEA;EACI,qBAAA;AACJ;;AAEA;EACI,aAAA;EACA,sBAAA;EACA,aAAA;AACJ;AACI;EACI,SAAA;EACA,aAAA;AACR","sourcesContent":[":root {\n    --black: #000;\n    --text-black: #333;\n    --white: #fff;\n    --light-green: #98fb98;\n    --main-bg: #312f3d;\n    --second-bg: #39374c;\n    --border-color: #4a485e;\n    --default-radius: 8px;\n};\n\n* {\n    box-sizing: border-box;\n}\n\nbody {\n    font-family: sans-serif;\n    margin: 0;\n    padding: 0;\n    background-color: var(--main-bg);\n    overflow-x: hidden;\n}\n\na {\n    text-decoration: none;\n}\n\nul {\n    padding: 0;\n}\n\nli {\n    list-style-type: none;\n}\n\n.App {\n    display: flex;\n    flex-direction: column;\n    height: 100vh;\n\n    main {\n        flex: 1;\n        padding: 20px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
