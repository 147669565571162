import { useEffect, useState } from 'react';
import styles from './Pagination.module.scss';
import { PaginationProps } from './Pagination.props';
import cn from 'classnames';

export const Pagination = ({ curPage, switchPage, totalPages }: PaginationProps) => {
  const [totalPagesArr, setTotalPagesArr] = useState<number[]>([]);

  const switchOnePage = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      switchPage(page);
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  const setPages = () => {
    const windowSize = 4;
    const windowStart = Math.max(1, curPage - Math.floor(windowSize / 2));
    const windowEnd = Math.min(totalPages, windowStart + windowSize - 1);

    const newTotalPages: number[] = [];
    for (let i = windowStart; i <= windowEnd; i++) {
      newTotalPages.push(i);
    }

    setTotalPagesArr(newTotalPages);
  };

  useEffect(() => {
    setPages();
  }, [curPage, totalPages]);

  return (
    <div className={styles.wrapper}>
      <div className={cn(styles.double, styles.left)} onClick={() => switchOnePage(1)}>
        <img src="/DoubleLeft.svg" alt="Последняя" height={38} />
      </div>
      <div className={styles.btn} onClick={() => switchOnePage(curPage - 1)}>
        <img src="/PrevArrow.svg" alt="Предыдущая" height={22} />
      </div>
      <div className={styles.paginationWrapper}>
        {totalPagesArr.map((item: number) => {
          return (
            <div
              className={cn(styles.item, {
                [styles.active]: curPage === item,
              })}
              onClick={() => {
                switchPage(item);
                window.scrollTo({ top: 0, behavior: 'smooth' });
              }}
              key={Math.random()}
            >
              {item}
            </div>
          );
        })}
      </div>
      <div className={styles.btn} onClick={() => switchOnePage(curPage + 1)}>
        <img src="/NextArrow.svg" alt="Следующая" height={22} />
      </div>
      <div className={cn(styles.double, styles.right)} onClick={() => switchOnePage(totalPages)}>
        <img src="/DoubleRight.svg" alt="Последняя" height={38} />
      </div>
    </div>
  );
};
