import { useEffect, useState } from 'react';
import { checkUser, getGroupByDate, getGroupIds, refreshGroup } from '../../api';
import { IAffUser, IPopulatedAffUser } from '../../types/AffUser';
import styles from './Group.module.scss';
import { AffUserItem, BackPage, Pagination, SortGroup } from '../../components';
import cn from 'classnames';

export const GroupPage = () => {
  const [loading, setLoading] = useState<boolean>(true);

  const [affUsers, setAffUsers] = useState<IAffUser[] | { message: string }>([]);
  const [curPage, setCurPage] = useState<number>(1);
  const [sort, setSort] = useState<string>('affilate_id::asc');
  const [totalPages, setTotalPages] = useState<number>(1);
  const urlParts: string[] = window.location.href.split('/');
  const groupDate: string[] = urlParts[urlParts.length - 1].split('T')[0].split('-');
  const formattedGroupDate: string = `${groupDate[2]}-${groupDate[1]}-${groupDate[0]}`;

  useEffect(() => {
    (async () => {
      const user = await checkUser();

      if (user.message.startsWith('error')) {
        window.location.href = '/login';
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const groupUsers: IPopulatedAffUser[] | { message: string } = await getGroupByDate(
        urlParts[urlParts.length - 1],
        +urlParts[4],
        curPage,
        sort,
      );
      if (
        'totalPages' in groupUsers &&
        'data' in groupUsers &&
        Array.isArray(groupUsers.data) &&
        typeof groupUsers.totalPages === 'number'
      ) {
        setAffUsers(groupUsers.data);
        setTotalPages(groupUsers.totalPages);
      }
    })();
  }, [curPage, sort]);

  if (loading) {
    const intervalId = setInterval(async () => {
      const groupUsers: IPopulatedAffUser[] | { message: string } = await getGroupByDate(
        urlParts[urlParts.length - 1],
        +urlParts[4],
        curPage,
        sort,
      );
      if (
        'data' in groupUsers &&
        'totalPages' in groupUsers &&
        Array.isArray(groupUsers.data) &&
        groupUsers.data.length > 0 &&
        groupUsers.data[0].group.is_loaded
      ) {
        setLoading(false);
        setAffUsers(groupUsers.data);
        setTotalPages(groupUsers.totalPages as number);
        clearInterval(intervalId);
      }
    }, 1500);
  };

  const getIds = async (slug: string, categoryId: number) => {
    const ids = await getGroupIds(slug, categoryId);

    if ('ids' in ids) {
      alert("Success copied")
      return navigator.clipboard.writeText(ids.ids);
    };
    
    return alert(ids.message);
  }

  const refresh = async () => {
    const response = await refreshGroup(
      urlParts[urlParts.length - 1],
      +urlParts[4],
    );

    if ("success" in response) {
      window.location.reload();
    } else {
      alert("API Error")
    }
  }

  return (
    <>
    {
      loading ? (
        <div className={styles.loader}>Обработка данных</div>
      ) : (
        <>
          <BackPage path={`/categories/${urlParts[4]}`} />
          <div className={styles.wrapper}>
            <div className={styles.dateWrapper}>
              {formattedGroupDate}
              <div className={styles.buttonsWrapper}>
                <div 
                  className={styles.btn} 
                  onClick={refresh}
                >
                  Обновить
                </div>
                <div className={styles.btn} onClick={() => getIds(urlParts[urlParts.length - 1], +urlParts[4])}>
                  Copy id's
                </div>
              </div>
            </div>
            <SortGroup sort={sort} setSort={setSort} />
            {Array.isArray(affUsers) &&
              affUsers.map((item: IAffUser) => {
                return <AffUserItem affUser={item} key={item.id} />;
              })}
            {'message' in affUsers && <div className={styles.notFound}>Ничего не найдено</div>}
            {totalPages > 1 && (
              <Pagination totalPages={totalPages} switchPage={setCurPage} curPage={curPage} />
            )}
          </div>
        </>
      )
    }
    </>
  );
};
