import { IGroup } from "../types";
import { protectedAxios } from "../utils";

export const createAffUsersGroup = async (groupId: number): Promise<IGroup | { message: string }> => {
    try {
        const { data } = 
            await protectedAxios.post(
                `${process.env.REACT_APP_API_URL}/aff-user/group-users/${groupId}`
            );
        
        return data;
    } catch (err) {
        console.log(err);
        return {
          message: 'create aff users err',
        };
    }
}