// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Categories_wrapper__7yw94 {
  background-color: var(--second-bg);
  padding: 40px;
  border-radius: var(--default-radius);
  border: 1px solid var(--border-color);
}
.Categories_wrapper__7yw94 .Categories_notFound__qXm5i {
  text-align: center;
  font-size: 40px;
  color: var(--white);
}`, "",{"version":3,"sources":["webpack://./src/pages/Categories/Categories.module.scss"],"names":[],"mappings":"AAAA;EACI,kCAAA;EACA,aAAA;EACA,oCAAA;EACA,qCAAA;AACJ;AACI;EACI,kBAAA;EACA,eAAA;EACA,mBAAA;AACR","sourcesContent":[".wrapper {\n    background-color: var(--second-bg);\n    padding: 40px;\n    border-radius: var(--default-radius);\n    border: 1px solid var(--border-color);\n\n    .notFound {\n        text-align: center;\n        font-size: 40px;\n        color: var(--white);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Categories_wrapper__7yw94`,
	"notFound": `Categories_notFound__qXm5i`
};
export default ___CSS_LOADER_EXPORT___;
