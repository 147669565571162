// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `._404_wrapper__SiGiw {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
._404_wrapper__SiGiw ._404_title__ONLue {
  color: var(--light-green);
  font-size: 40px;
}
._404_wrapper__SiGiw ._404_title__ONLue span {
  font-style: italic;
}
._404_wrapper__SiGiw a {
  padding: 10px 26px 10px 20px;
  border: 2px solid var(--light-green);
  color: var(--light-green);
  border-radius: 8px;
  cursor: pointer;
  transition: 0.6s;
}
._404_wrapper__SiGiw a:hover {
  opacity: 0.5;
}`, "",{"version":3,"sources":["webpack://./src/pages/404/404.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;AACJ;AACI;EACI,yBAAA;EACA,eAAA;AACR;AACQ;EACI,kBAAA;AACZ;AAGI;EACI,4BAAA;EACA,oCAAA;EACA,yBAAA;EACA,kBAAA;EACA,eAAA;EACA,gBAAA;AADR;AAGQ;EACI,YAAA;AADZ","sourcesContent":[".wrapper {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    height: 100%;\n\n    .title {\n        color: var(--light-green);\n        font-size: 40px;\n\n        span {\n            font-style: italic;\n        }\n    }\n\n    a {\n        padding: 10px 26px 10px 20px;\n        border: 2px solid var(--light-green);\n        color: var(--light-green);\n        border-radius: 8px;\n        cursor: pointer;\n        transition: 0.6s;\n\n        &:hover {\n            opacity: 0.5;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `_404_wrapper__SiGiw`,
	"title": `_404_title__ONLue`
};
export default ___CSS_LOADER_EXPORT___;
