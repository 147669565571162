// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Header_wrapper__BDVE2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  padding: 20px 60px;
  background-color: var(--second-bg);
  border-bottom: 1px solid var(--border-color);
}
.Header_wrapper__BDVE2 .Header_logoWrapper__KJ2JZ svg {
  transition: 0.6s;
}
.Header_wrapper__BDVE2 .Header_logoWrapper__KJ2JZ:hover svg {
  fill: #b9b8bd;
}
.Header_wrapper__BDVE2 .Header_navWrapper__e2bEM {
  display: flex;
}
.Header_wrapper__BDVE2 .Header_navWrapper__e2bEM .Header_navItem__0pJ9o:not(:last-child) {
  margin-right: 40px;
}
.Header_wrapper__BDVE2 .Header_navWrapper__e2bEM .Header_navItem__0pJ9o a {
  padding: 10px 26px 10px 20px;
  border: 2px solid var(--light-green);
  color: var(--light-green);
  border-radius: 8px;
  cursor: pointer;
  transition: 0.6s;
}
.Header_wrapper__BDVE2 .Header_navWrapper__e2bEM .Header_navItem__0pJ9o a:hover {
  opacity: 0.5;
}`, "",{"version":3,"sources":["webpack://./src/Layout/Header/Header.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,YAAA;EACA,kBAAA;EACA,kCAAA;EACA,4CAAA;AACJ;AAGQ;EACI,gBAAA;AADZ;AAMY;EACI,aAAA;AAJhB;AASI;EACI,aAAA;AAPR;AAWY;EACI,kBAAA;AAThB;AAYY;EACI,4BAAA;EACA,oCAAA;EACA,yBAAA;EACA,kBAAA;EACA,eAAA;EACA,gBAAA;AAVhB;AAYgB;EACI,YAAA;AAVpB","sourcesContent":[".wrapper {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    width: 100vw;\n    padding: 20px 60px;\n    background-color: var(--second-bg);\n    border-bottom: 1px solid var(--border-color);\n\n    .logoWrapper {\n\n        svg {\n            transition: 0.6s;\n        }\n\n        &:hover {\n\n            svg {\n                fill: #b9b8bd;\n            }\n        }\n    }\n\n    .navWrapper {\n        display: flex;\n\n        .navItem {\n\n            &:not(:last-child) {\n                margin-right: 40px;\n            }\n\n            a {\n                padding: 10px 26px 10px 20px;\n                border: 2px solid var(--light-green);\n                color: var(--light-green);\n                border-radius: 8px;\n                cursor: pointer;\n                transition: 0.6s;\n\n                &:hover {\n                    opacity: 0.5;\n                }\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `Header_wrapper__BDVE2`,
	"logoWrapper": `Header_logoWrapper__KJ2JZ`,
	"navWrapper": `Header_navWrapper__e2bEM`,
	"navItem": `Header_navItem__0pJ9o`
};
export default ___CSS_LOADER_EXPORT___;
