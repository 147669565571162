import { useState } from 'react';
import styles from './CreateGroupForm.module.scss';
import cn from 'classnames';
import { createAffUsersGroup, createCategory, createGroup } from '../../api';
import { ICategory } from '../../types';

export const CreateGroupForm = () => {
  const [file, setFile] = useState<File | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [successUpload, setSuccessUpload] = useState<boolean>(false);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const selectedFile = event.target.files[0];
      setFile(selectedFile);
    }
  };

  const postFile = async () => {
    if (file !== null) {
      setLoading(true);
      const modifiedFile = new File([file], 'group.xlsx', { type: file.type });
      const category = await createCategory(modifiedFile);
      if ('id' in category) {
        const group = await createGroup(category.id);
        if ('message' in group) {
          alert('Можно загружать только одну группу в один день');
          return window.location.reload();
        }

        if ('id' in group) {
          setTimeout(() => {
            window.location.href = `/categories`;
          }, 1000);
          const groupAffUsers = await createAffUsersGroup(group.id);
        }
      }
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.titleWrapper}>Загрузить группу</div>
      <div className={styles.inputWrapper}>
        <label htmlFor="file" className={styles.label}>
          <span>Выберите файл</span>
        </label>
        {file && <div className={styles.uploadFileInfo}>{file.name}</div>}
        <input
          id="file"
          className={styles.htmlInput}
          onChange={handleFileChange}
          type="file"
          accept=".xlsx"
        />
      </div>
      <div className={styles.uploadWrapper}>
        <button
          className={cn(styles.uploadBtn, {
            [styles.active]: file,
            [styles.success]: successUpload,
            [styles.loading]: loading,
          })}
          disabled={loading ? true : false}
          onClick={postFile}
        >
          <div className={styles.upload}>Загрузить</div>
        </button>
      </div>
    </div>
  );
};
