import { Route, Routes } from 'react-router';
import { CreateGroupPage } from './pages/CreateGroup/CreateGroup';
import { AllGroupsPage } from './pages/AllGroups/AllGroups';
import { Helmet } from 'react-helmet';
import { Footer, Header } from './Layout';
import { GroupPage } from './pages/Group/Group';
import { CategoriesPage } from './pages/Categories/Categories';
import { LoginPage } from './pages/Login/Login';
import { useEffect, useState } from 'react';
import { checkUser } from './api';
import { Error404 } from './pages/404/404';

function App() {
  const [auth, setAuth] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      const user = await checkUser();
      if (user.message.startsWith('error') && window.location.pathname !== '/login') {
        window.location.href = '/login';
      } else {
        setAuth(true);
      }
    })();
  });

  return (
    <div className="App">
      <Helmet>
        <title>Retention crm</title>
      </Helmet>
      {window.location.pathname === '/login' ? (
        <Routes>
          <Route path="/login" element={<LoginPage />} />
        </Routes>
      ) : (
        <>
          <Header />
          <main>
            <Routes>
              <Route path="/" element={auth ? <CreateGroupPage /> : <div>loading...</div>} />
              <Route
                path="/categories"
                element={auth ? <CategoriesPage /> : <div>loading...</div>}
              />
              <Route
                path="/categories/:id"
                element={auth ? <AllGroupsPage /> : <div>loading...</div>}
              />
              <Route
                path="/categories/:id/:slug"
                element={auth ? <GroupPage /> : <div>loading...</div>}
              />
              <Route path="*" element={auth ? <Error404 /> : <div>loading...</div>} />
            </Routes>
          </main>
          <Footer />
        </>
      )}
    </div>
  );
}

export default App;
