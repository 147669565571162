import { deleteCategory, deleteGroup } from '../../api';
import styles from './DeleteForm.module.scss';
import { IDeleteFormProps } from './DeleteForm.props';
import cn from 'classnames';

export const DeleteForm = ({ entityId, name, setOpen, entity }: IDeleteFormProps) => {
  const deleteItem = async () => {
    switch (entity) {
      case 'group':
        await deleteGroup(entityId);
        break;
      case 'category':
        await deleteCategory(entityId);
        break;
    }
    window.location.reload();
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.bg} onClick={() => setOpen(false)}></div>
      <div className={styles.formWrapper}>
        <div className={styles.titleWrapper}>Вы действительно хотите удалить {name} ?</div>
        <div className={styles.optionWrapper}>
          <div className={cn(styles.red, styles.option)} onClick={deleteItem}>
            Да
          </div>
          <div className={cn(styles.green, styles.option)} onClick={() => setOpen(false)}>
            Нет
          </div>
        </div>
      </div>
    </div>
  );
};
