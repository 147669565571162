// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GroupListItem_wrapper__Ujent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 10px 20px;
  border-radius: var(--default-radius);
  transition: 0.6s;
  cursor: pointer;
  color: var(--text-black);
}
.GroupListItem_wrapper__Ujent:hover {
  background-color: rgba(255, 255, 255, 0.9);
}
.GroupListItem_wrapper__Ujent:not(:last-child) {
  margin-bottom: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/GroupListItem/GroupListItem.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,WAAA;EACA,0CAAA;EACA,kBAAA;EACA,oCAAA;EACA,gBAAA;EACA,eAAA;EACA,wBAAA;AACJ;AACI;EACI,0CAAA;AACR;AAEI;EACI,mBAAA;AAAR","sourcesContent":[".wrapper {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    width: 100%;\n    background-color: rgba(255, 255, 255, 0.5);\n    padding: 10px 20px;\n    border-radius: var(--default-radius);\n    transition: 0.6s;\n    cursor: pointer;\n    color: var(--text-black);\n\n    &:hover {\n        background-color: rgba(255, 255, 255, 0.9);\n    }\n\n    &:not(:last-child) {\n        margin-bottom: 10px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `GroupListItem_wrapper__Ujent`
};
export default ___CSS_LOADER_EXPORT___;
