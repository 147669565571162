import { Link } from 'react-router-dom';
import styles from './Header.module.scss';

export const Header = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.logoWrapper}>
        <Link to="/">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="300"
            height="45"
            viewBox="0 0 473 45"
            fill="none"
          >
            <g clipPath="url(#clip0_552_43684)">
              <path
                d="M63.8498 15.4V35.28C63.8498 37.49 64.2598 39.02 65.0698 39.85C65.8798 40.68 67.3198 41.09 69.3898 41.09H72.7598V43.31H68.9998C66.3798 43.31 64.4498 42.69 63.2098 41.45C61.9698 40.21 61.3532 38.1533 61.3598 35.28V15.4H56.8198V13.24H61.3598V5.59998H63.8498V13.24H72.7598V15.4H63.8498Z"
                fill="white"
              />
              <path
                d="M101.67 15.9301C103.923 18.1234 105.05 21.3801 105.05 25.7001V43.3101H102.61V25.8601C102.61 22.2801 101.7 19.5401 99.9001 17.6401C98.9404 16.6744 97.7882 15.9216 96.5185 15.4306C95.2487 14.9395 93.8898 14.7213 92.5301 14.7901C91.0874 14.7235 89.6471 14.9656 88.3055 15.5002C86.9638 16.0348 85.7517 16.8496 84.7501 17.8901C82.8101 19.9634 81.8401 23.0267 81.8401 27.0801V43.3101H79.3501V2.31006H81.8401V19.6101C82.624 17.443 84.1324 15.6131 86.1101 14.4301C88.1945 13.2005 90.5807 12.5771 93.0001 12.6301C94.5925 12.5727 96.1801 12.8352 97.6693 13.402C99.1585 13.9688 100.519 14.8285 101.67 15.9301V15.9301Z"
                fill="white"
              />
              <path
                d="M140.82 29.24H114.82C114.806 31.6255 115.416 33.9731 116.59 36.05C117.631 37.8303 119.162 39.2742 121 40.21C122.838 41.1362 124.871 41.6093 126.93 41.59C129.575 41.6814 132.178 40.913 134.35 39.4C136.324 37.9763 137.671 35.8443 138.11 33.45H140.72C140.187 36.4144 138.564 39.072 136.17 40.9C133.505 42.8756 130.245 43.8811 126.93 43.75C124.298 43.7948 121.702 43.1464 119.4 41.87C117.181 40.6073 115.372 38.7327 114.19 36.47C112.893 33.9277 112.247 31.1033 112.31 28.25C112.243 25.4062 112.879 22.5898 114.16 20.05C115.33 17.8034 117.13 15.9474 119.34 14.71C121.666 13.4421 124.281 12.8012 126.93 12.85C129.552 12.7847 132.143 13.4272 134.43 14.71C136.476 15.8667 138.157 17.5749 139.28 19.64C140.38 21.6689 140.95 23.9422 140.94 26.25C140.93 27.58 140.89 28.58 140.82 29.24ZM137.02 20.44C136.042 18.6596 134.546 17.2191 132.73 16.31C130.903 15.4205 128.892 14.9718 126.86 15C124.83 14.967 122.822 15.4159 121 16.31C119.18 17.243 117.664 18.6757 116.63 20.44C115.443 22.4911 114.822 24.8204 114.83 27.19H138.41C138.594 24.8515 138.104 22.5093 137 20.44H137.02Z"
                fill="white"
              />
              <path
                d="M151.75 14.57L154.6 16.26C156.535 15.3204 158.572 14.6058 160.67 14.13C162.946 13.631 165.27 13.3862 167.6 13.4C169.895 13.3512 172.184 13.6479 174.39 14.28C176.064 14.7791 177.628 15.59 179 16.67C180.924 15.5888 182.982 14.765 185.12 14.22C187.427 13.6568 189.795 13.3814 192.17 13.4C194.774 13.3269 197.37 13.7128 199.84 14.54C201.675 15.1671 203.332 16.227 204.67 17.63C205.866 18.9498 206.727 20.5378 207.18 22.26C207.678 24.1221 207.923 26.0426 207.91 27.97V43.71H198.6V29.14C198.6 28.21 198.6 27.3 198.49 26.43C198.428 25.6078 198.178 24.8109 197.76 24.1C197.294 23.3687 196.613 22.7995 195.81 22.47C194.603 22.0036 193.313 21.7925 192.02 21.85C190.409 21.8438 188.8 21.9709 187.21 22.23C185.734 22.486 184.282 22.8674 182.87 23.37C183.062 24.1142 183.189 24.8737 183.25 25.64C183.31 26.42 183.34 27.2 183.34 27.97V43.71H174V29.14C174 28.21 174 27.3 173.88 26.43C173.821 25.6086 173.575 24.8116 173.16 24.1C172.682 23.3593 171.982 22.7888 171.16 22.47C169.957 22.0024 168.67 21.7912 167.38 21.85C164.424 21.8128 161.485 22.2865 158.69 23.25V43.71H149.37V14.57H151.75Z"
                fill="#98FB98"
              />
              <path
                d="M231 44.8699C228.341 44.9475 225.689 44.5652 223.16 43.7399C221.285 43.1104 219.587 42.0405 218.21 40.6199C216.994 39.3107 216.116 37.725 215.65 35.9999C215.15 34.1451 214.905 32.2309 214.92 30.3099V14.5699H224.25V29.1399C224.25 30.0699 224.25 30.9899 224.36 31.8799C224.419 32.7162 224.702 33.5212 225.18 34.2099C225.756 34.9488 226.534 35.5047 227.42 35.8099C230.297 36.6235 233.343 36.6235 236.22 35.8099C237.108 35.5043 237.89 34.9487 238.47 34.2099C238.941 33.5182 239.22 32.7144 239.28 31.8799C239.36 30.9899 239.4 30.0699 239.4 29.1399V14.5699H248.72V30.2999C248.745 32.2241 248.502 34.1424 248 35.9999C247.516 37.7288 246.624 39.3164 245.4 40.6299C244.031 42.0459 242.345 43.1154 240.48 43.7499C237.962 44.5771 235.319 44.9595 232.67 44.8799L231 44.8699Z"
                fill="#98FB98"
              />
              <path d="M258.63 0L265.63 4.08V43.71H256.3V0H258.63Z" fill="#98FB98" />
              <path
                d="M282.52 14.57H291.52V22.73H282.52V32.05C282.514 32.6356 282.555 33.2207 282.64 33.8C282.708 34.2852 282.932 34.7348 283.28 35.08C283.741 35.4876 284.305 35.7609 284.91 35.87C285.927 36.0722 286.963 36.1594 288 36.13C288.9 36.13 289.83 36.08 290.8 35.99C291.687 35.9041 292.564 35.7367 293.42 35.49V44C292.538 44.2089 291.642 44.356 290.74 44.44C289.74 44.53 288.68 44.58 287.59 44.58C285.333 44.6249 283.081 44.3727 280.89 43.83C279.274 43.4235 277.764 42.6769 276.46 41.64C275.342 40.6895 274.494 39.4618 274 38.08C273.447 36.5207 273.179 34.8744 273.21 33.22V2.91003H275.54L282.54 6.99003L282.52 14.57Z"
                fill="#98FB98"
              />
              <path
                d="M299.83 4.37C299.803 3.24818 300.175 2.15324 300.88 1.28C301.58 0.43 302.88 0 304.88 0H305.35C307.29 0 308.61 0.43 309.35 1.28C310.055 2.15324 310.427 3.24818 310.4 4.37V5.07C310.427 6.19182 310.055 7.28676 309.35 8.16C308.65 9.01 307.35 9.44 305.35 9.44H304.88C302.94 9.44 301.62 9.01 300.88 8.16C300.175 7.28676 299.803 6.19182 299.83 5.07V4.37ZM300.42 14.57V43.71H309.74V18.65L302.74 14.57H300.42Z"
                fill="#98FB98"
              />
              <path
                d="M317.31 11.71C317.278 10.0099 317.546 8.31752 318.1 6.70997C318.603 5.30542 319.457 4.05258 320.58 3.06997C321.871 2.01264 323.381 1.25448 325 0.849968C327.186 0.276644 329.441 0.0107527 331.7 0.0599679C332.79 0.0599679 333.84 0.109968 334.85 0.199968C335.752 0.284042 336.648 0.431066 337.53 0.639968V9.14997C336.68 8.90414 335.81 8.73677 334.93 8.64997C333.98 8.55997 333.06 8.50997 332.17 8.50997C331.132 8.48232 330.094 8.57968 329.08 8.79997C328.459 8.9365 327.886 9.23691 327.42 9.66997C327.055 10.0429 326.82 10.5232 326.75 11.04C326.664 11.6495 326.624 12.2645 326.63 12.88V14.57H335.63V22.73H326.63V43.73H317.3L317.31 11.71Z"
                fill="#98FB98"
              />
              <path
                d="M342.38 28C342.367 26.0811 342.619 24.1696 343.13 22.32C343.623 20.5773 344.545 18.9857 345.81 17.69C347.257 16.2694 349.013 15.2018 350.94 14.57C353.543 13.7332 356.267 13.3378 359 13.4H360.64C363.385 13.3236 366.123 13.709 368.74 14.54C370.664 15.1719 372.416 16.2396 373.86 17.66C375.132 18.9525 376.057 20.545 376.55 22.29C377.061 24.1396 377.313 26.0511 377.3 27.97V30.3C377.314 32.2222 377.062 34.1371 376.55 35.99C376.057 37.735 375.132 39.3275 373.86 40.62C372.416 42.0404 370.664 43.1081 368.74 43.74C366.122 44.5673 363.384 44.9492 360.64 44.87H359C356.256 44.9492 353.518 44.5673 350.9 43.74C348.973 43.1082 347.217 42.0405 345.77 40.62C344.52 39.3227 343.613 37.7351 343.13 36C342.618 34.1471 342.366 32.2322 342.38 30.31V28ZM351.7 29.17C351.7 30.1 351.7 31.02 351.82 31.91C351.879 32.7595 352.192 33.5716 352.72 34.24C353.366 34.9833 354.203 35.5365 355.14 35.84C358.224 36.6533 361.466 36.6533 364.55 35.84C365.497 35.5386 366.343 34.9855 367 34.24C367.523 33.5692 367.836 32.7585 367.9 31.91C367.98 31.02 368.02 30.1 368.02 29.17C368.02 28.24 368.02 27.32 367.9 26.43C367.836 25.5815 367.523 24.7708 367 24.1C366.346 23.3513 365.498 22.7977 364.55 22.5C361.468 21.6732 358.222 21.6732 355.14 22.5C354.201 22.7998 353.364 23.3536 352.72 24.1C352.192 24.7684 351.879 25.5804 351.82 26.43C351.74 27.29 351.7 28.21 351.7 29.14V29.17Z"
                fill="#98FB98"
              />
              <path
                d="M407.65 22.61C405.86 22.3018 404.046 22.1479 402.23 22.15C399.327 22.1099 396.436 22.5215 393.66 23.37V43.71H384.34V14.57H386.67L389.81 16.43C391.687 15.4199 393.687 14.6569 395.76 14.16C398.056 13.6345 400.405 13.3794 402.76 13.4C403.65 13.4 404.51 13.4 405.33 13.52C406.15 13.64 406.92 13.7 407.66 13.81L407.65 22.61Z"
                fill="#98FB98"
              />
              <path
                d="M416.27 14.5701L419.13 16.2601C421.061 15.319 423.095 14.6043 425.19 14.1301C427.466 13.6316 429.79 13.3868 432.12 13.4001C434.415 13.3507 436.704 13.6474 438.91 14.2801C440.594 14.7768 442.168 15.5878 443.55 16.6701C445.47 15.5876 447.524 14.7638 449.66 14.2201C451.97 13.6563 454.342 13.3808 456.72 13.4001C459.32 13.3277 461.913 13.7136 464.38 14.5401C466.216 15.1696 467.876 16.229 469.22 17.6301C470.413 18.9505 471.27 20.5385 471.72 22.2601C472.218 24.1221 472.463 26.0426 472.45 27.9701V43.7101H463.13V29.1401C463.13 28.2101 463.13 27.3001 463.01 26.4301C462.947 25.6079 462.698 24.8109 462.28 24.1001C461.813 23.3688 461.132 22.7995 460.33 22.4701C459.123 22.0037 457.833 21.7925 456.54 21.8501C454.929 21.8439 453.32 21.971 451.73 22.2301C450.254 22.4861 448.802 22.8674 447.39 23.3701C447.587 24.1133 447.714 24.8732 447.77 25.6401C447.83 26.4201 447.86 27.2001 447.86 27.9701V43.7101H438.53V29.1401C438.53 28.2101 438.53 27.3001 438.42 26.4301C438.357 25.6079 438.108 24.8109 437.69 24.1001C437.223 23.3688 436.542 22.7995 435.74 22.4701C434.533 22.0037 433.243 21.7925 431.95 21.8501C428.994 21.8139 426.055 22.2875 423.26 23.2501V43.7101H413.94V14.5701H416.27Z"
                fill="#98FB98"
              />
              <path
                d="M31.22 16.83L28.83 14.44C28.2118 13.8263 27.3761 13.4819 26.505 13.4819C25.6339 13.4819 24.7982 13.8263 24.18 14.44L16.11 22.51L8.00001 14.38C7.39671 13.7803 6.58064 13.4437 5.73001 13.4437C4.87938 13.4437 4.06331 13.7803 3.46001 14.38L0.940007 16.89C0.642182 17.1872 0.4059 17.5403 0.244686 17.9289C0.0834706 18.3176 0.000488281 18.7342 0.000488281 19.155C0.000488281 19.5757 0.0834706 19.9924 0.244686 20.381C0.4059 20.7697 0.642182 21.1227 0.940007 21.42L13.82 34.3C14.2738 34.7554 14.8547 35.063 15.4864 35.1826C16.1181 35.3021 16.7712 35.228 17.36 34.97C17.774 34.8068 18.1488 34.558 18.46 34.24L31.22 21.48C31.5263 21.1752 31.7693 20.8129 31.9352 20.4139C32.101 20.0149 32.1864 19.5871 32.1864 19.155C32.1864 18.7229 32.101 18.2951 31.9352 17.8961C31.7693 17.4971 31.5263 17.1348 31.22 16.83V16.83Z"
                fill="#98FB98"
              />
              <path
                d="M0.729994 35.1001V42.1001C0.729994 42.5377 0.903832 42.9573 1.21327 43.2668C1.5227 43.5762 1.94239 43.7501 2.37999 43.7501H9.37999C9.70517 43.7467 10.0222 43.6478 10.2917 43.4658C10.5612 43.2838 10.7713 43.0267 10.8959 42.7263C11.0205 42.4259 11.0542 42.0956 10.9927 41.7762C10.9312 41.4569 10.7773 41.1627 10.55 40.9301L3.54999 33.9301C3.31921 33.698 3.02463 33.5398 2.70369 33.4755C2.38276 33.4113 2.04998 33.444 1.74767 33.5694C1.44536 33.6949 1.18718 33.9074 1.00597 34.1799C0.824754 34.4525 0.72869 34.7728 0.729994 35.1001V35.1001Z"
                fill="white"
              />
              <path
                d="M31.4902 35.1001V42.1001C31.4902 42.5377 31.3163 42.9573 31.0069 43.2668C30.6975 43.5762 30.2778 43.7501 29.8402 43.7501H22.8402C22.5129 43.7514 22.1926 43.6553 21.92 43.4741C21.6475 43.2929 21.435 43.0347 21.3096 42.7324C21.1841 42.4301 21.1514 42.0973 21.2157 41.7764C21.2799 41.4554 21.4381 41.1608 21.6702 40.9301L28.6702 33.9301C28.9009 33.698 29.1955 33.5398 29.5165 33.4755C29.8374 33.4113 30.1702 33.444 30.4725 33.5694C30.7748 33.6949 31.033 33.9074 31.2142 34.1799C31.3954 34.4525 31.4915 34.7728 31.4902 35.1001V35.1001Z"
                fill="white"
              />
            </g>
            <defs>
              <clipPath id="clip0_552_43684">
                <rect width="472.45" height="44.87" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </Link>
      </div>
      <ul className={styles.navWrapper}>
        <li className={styles.navItem}>
          <Link to="/">Загрузить группу</Link>
        </li>
        <li className={styles.navItem}>
          <Link to="/categories">Категории</Link>
        </li>
      </ul>
    </div>
  );
};
