import styles from './SortGroup.module.scss';
import { SortGroupProps } from './SortGroup.props';
import cn from 'classnames';

export const SortGroup = ({ sort, setSort }: SortGroupProps) => {
  const switchSort = (sortSwitch: string) => {
    if (sortSwitch === 'affilate_id') {
      if (sort === 'affilate_id::asc') {
        setSort('affilate_id::desc');
      } else {
        setSort('affilate_id::asc');
      }
    }

    if (sortSwitch === 'username') {
      if (sort === 'username::asc') {
        setSort('username::desc');
      } else {
        setSort('username::asc');
      }
    }

    if (sortSwitch === 'available_sum') {
      if (sort === 'available_sum::asc') {
        setSort('available_sum::desc');
      } else {
        setSort('available_sum::asc');
      }
    }

    if (sortSwitch === 'diff') {
      if (sort === 'diff::asc') {
        setSort('diff::desc');
      } else {
        setSort('diff::asc');
      }
    }

    if (sortSwitch === 'date_paid') {
      if (sort === 'date_paid::asc') {
        setSort('date_paid::desc');
      } else {
        setSort('date_paid::asc');
      }
    }

    if (sortSwitch === 'country') {
      if (sort === 'country::asc') {
        setSort('country::desc');
      } else {
        setSort('country::asc');
      }
    }

    if (sortSwitch === 'registration_date') {
      if (sort === 'registration_date::asc') {
        setSort('registration_date::desc');
      } else {
        setSort('registration_date::asc');
      }
    }

    if (sortSwitch === 'has_deposit') {
      if (sort === 'has_deposit::asc') {
        setSort('has_deposit::desc');
      } else {
        setSort('has_deposit::asc');
      }
    }
  };
  return (
    <div className={styles.sortWrapper}>
      <div className={cn(styles.id, styles.sortSetting)} onClick={() => switchSort('affilate_id')}>
        Aff id
        {sort === 'affilate_id::asc' && (
          <div className={styles.up}>
            <svg
              width="6"
              height="12"
              viewBox="0 0 8 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1L7 7L1 13"
                stroke="#98fb98"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        )}
        {sort === 'affilate_id::desc' && (
          <div className={styles.down}>
            <svg
              width="6"
              height="12"
              viewBox="0 0 8 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1L7 7L1 13"
                stroke="#98fb98"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        )}
      </div>
      <div
        className={cn(styles.username, styles.sortSetting)}
        onClick={() => switchSort('username')}
      >
        Username
        {sort === 'username::asc' && (
          <div className={styles.up}>
            <svg
              width="6"
              height="12"
              viewBox="0 0 8 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1L7 7L1 13"
                stroke="#98fb98"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        )}
        {sort === 'username::desc' && (
          <div className={styles.down}>
            <svg
              width="6"
              height="12"
              viewBox="0 0 8 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1L7 7L1 13"
                stroke="#98fb98"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        )}
      </div>
      <div
        className={cn(styles.registration, styles.sortSetting)}
        onClick={() => switchSort('registration_date')}
      >
        Registration date
        {sort === 'registration_date::asc' && (
          <div className={styles.up}>
            <svg
              width="6"
              height="12"
              viewBox="0 0 8 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1L7 7L1 13"
                stroke="#98fb98"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        )}
        {sort === 'registration_date::desc' && (
          <div className={styles.down}>
            <svg
              width="6"
              height="12"
              viewBox="0 0 8 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1L7 7L1 13"
                stroke="#98fb98"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        )}
      </div>
      <div className={cn(styles.country, styles.sortSetting)} onClick={() => switchSort('country')}>
        Country
        {sort === 'country::asc' && (
          <div className={styles.up}>
            <svg
              width="6"
              height="12"
              viewBox="0 0 8 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1L7 7L1 13"
                stroke="#98fb98"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        )}
        {sort === 'country::desc' && (
          <div className={styles.down}>
            <svg
              width="6"
              height="12"
              viewBox="0 0 8 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1L7 7L1 13"
                stroke="#98fb98"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        )}
      </div>
      <div className={cn(styles.phone, styles.sortSetting)}>Phone</div>
      <div className={cn(styles.deposit, styles.sortSetting)} onClick={() => switchSort('has_deposit')}>
        Has deposit
        {sort === 'has_deposit::asc' && (
          <div className={styles.up}>
            <svg
              width="6"
              height="12"
              viewBox="0 0 8 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1L7 7L1 13"
                stroke="#98fb98"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        )}
        {sort === 'has_deposit::desc' && (
          <div className={styles.down}>
            <svg
              width="6"
              height="12"
              viewBox="0 0 8 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1L7 7L1 13"
                stroke="#98fb98"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        )}
      </div>
      <div className={cn(styles.available_sum, styles.sortSetting)} onClick={() => switchSort('available_sum')}>
        Available sum
        {sort === 'available_sum::asc' && (
          <div className={styles.up}>
            <svg
              width="6"
              height="12"
              viewBox="0 0 8 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1L7 7L1 13"
                stroke="#98fb98"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        )}
        {sort === 'available_sum::desc' && (
          <div className={styles.down}>
            <svg
              width="6"
              height="12"
              viewBox="0 0 8 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1L7 7L1 13"
                stroke="#98fb98"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        )}
      </div>
      <div className={cn(styles.date_paid, styles.sortSetting)} onClick={() => switchSort('date_paid')}>
        Date paid
        {sort === 'date_paid::asc' && (
          <div className={styles.up}>
            <svg
              width="6"
              height="12"
              viewBox="0 0 8 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1L7 7L1 13"
                stroke="#98fb98"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        )}
        {sort === 'date_paid::desc' && (
          <div className={styles.down}>
            <svg
              width="6"
              height="12"
              viewBox="0 0 8 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1L7 7L1 13"
                stroke="#98fb98"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        )}
      </div>
      <div className={cn(styles.players, styles.sortSetting)} onClick={() => switchSort('diff')}>
        Players
        {sort === 'diff::asc' && (
          <div className={styles.up}>
            <svg
              width="6"
              height="12"
              viewBox="0 0 8 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1L7 7L1 13"
                stroke="#98fb98"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        )}
        {sort === 'diff::desc' && (
          <div className={styles.down}>
            <svg
              width="6"
              height="12"
              viewBox="0 0 8 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1L7 7L1 13"
                stroke="#98fb98"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        )}
      </div>
    </div>
  );
};
