import { Link } from 'react-router-dom';
import styles from './404.module.scss';

export const Error404 = () => {
  return (
    <div className={styles.wrapper}>
      <h1 className={styles.title}>
        Страница не найдена <span>404</span>
      </h1>
      <Link to="/">Перейти на главную</Link>
    </div>
  );
};
