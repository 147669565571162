import { Link } from 'react-router-dom';
import styles from './GroupListItem.module.scss';
import { GroupListItemProps } from './GroupListItem.props';
import { useState } from 'react';
import { DeleteForm } from '../DeleteForm/DeleteForm';

export const GroupListItem = ({ group, categoryId }: GroupListItemProps) => {
  const [deleteForm, setDeleteForm] = useState<boolean>(false);
  const groupDate: string[] = group.date.split('T')[0].split('-');
  const formattedGroupDate: string = `${groupDate[2]}-${groupDate[1]}-${groupDate[0]}`;

  const handleDeleteForm = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    setDeleteForm(true);
  };

  return (
    <>
      {deleteForm && (
        <DeleteForm
          name={formattedGroupDate}
          setOpen={setDeleteForm}
          entityId={group.id}
          entity="group"
        />
      )}
      <Link to={`/categories/${categoryId}/${group.date.split("T")[0]}`} className={styles.wrapper}>
        {formattedGroupDate}
        <div className={styles.deleteItemWrapper} onClick={(e) => handleDeleteForm(e)}>
          <img src="/Trash.svg" width={24} height={30} />
        </div>
      </Link>
    </>
  );
};
